import { RealtyTypes } from "~/constants/realty";
import type { RealtyType } from "~/types/common/realty";

/** Helper для работы с объектом (Realty) */
class realtyHelper {
    /** 
    * Получает текстовый формат (название) типа объекта
    * @param $t транслятор
    * @param realtyType тип объекта
    */
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    public getRealtyTypeText = ($t: any, realtyType: RealtyType) : string => {
        switch (realtyType) {
        case RealtyTypes.Apartment:
            return $t('realty.apartment');
        case RealtyTypes.ParkingSpace:
            return $t('realty.parking_space');
        case RealtyTypes.Storage:
            return $t('realty.storage');
        case RealtyTypes.NonResidential:
            return $t('realty.non_residential');
        case RealtyTypes.Undefined:
        default:
            return $t('realty.undefined');
        } 
    };
};

export default new realtyHelper();